import {
  Box,
  Collapse,
  Grid,
  Switch,
  TextField
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from 'react-intl'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import * as Utils from '../../commons/utils/Utils'

const SELECT_OPTIONS_INIT = {
  bases: [],
  tipoModelos: [],
  gerenciadores: [],
  servicios: [],
  estados: [],
  paises: [],
  tipos: [],
  enTallerSelect: [],
  abonoSelect: [],
  realizadoSelect: [],
  regiones: [],
  cecos: [],
  cebes: [],
  nivelesAprobacion: [],
  origenes: [],
}

const FILTER_STATE_INIT = {
  ticketTipo: '',
  id: '',
  estado: '',
  nivelAprobacionPresupuesto: null,
  fechaHoraAlta: '',
  detalle: '',
  servicio: null,
  entidad: '',
  gerenciador: null,
  region: null,
  base: null,
  pais: [],
  centroCostos: '',
  centroBeneficios: '',
  responsable1: '',
  enTaller: null,
  mttTotal: '',
  mttParcial: '',
  encuestaNivelSatisfaccion: '',
  abono: null,
  fechaRealizado: '',
  fechaHoraTurno: '',
  tipoModelo: null,
  origen: null,
}

const FILTER_QUERY_INIT = {
  entidad: '',
  ticketTipo: 'DEFAULT',
  id: '',
  estado: 'PENDIENTES',
  fechaHoraAlta: '',
  servicio: '',
  movilPersona: '',
  gerenciador: '',
  region: '',
  base: '',
  pais: [],
  fechaRealizado: '',
  enTaller: '',
  detalle: '',
  abono: '',
  centroCostos: '',
  centroBeneficios: '',
  responsable1: '',
  encuestaNivelSatisfaccion: '',
  fechaHoraTurno: '',
  nivelAprobacionPresupuesto: '',
  tipoModelo: '',
  enTallerKpi: '',
  mttTotal: '',
  mttParcial: '',
  origen: '',
}

const getFilterState = (intl) => {
  let filterStateInit = FILTER_STATE_INIT
  filterStateInit.ticketTipo = ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true' ? { value: 'DEFAULT', label: intl.formatMessage({ id: 'ticketsGridFilters.select_por_defecto', defaultMessage: 'POR DEFECTO' }) } : ''
  filterStateInit.estado = { value: 'PENDIENTES', label: intl.formatMessage({ id: 'ticketsGridFilters.select_pendientes', defaultMessage: 'PENDIENTES' }) }
  let file = JSON.parse(window.localStorage.getItem('TicketsGridFilterNew'))

  if (file && file.activo && file.filtros) {
    filterStateInit = file.filtros
  }

  return filterStateInit
}

const getPersistedStoragedState = () => {
  let file = JSON.parse(window.localStorage.getItem('TicketsGridFilterNew'))
  return (file !== null && file.activo === true)
}

const TicketGridFilters = (props) => {
  const intl = useIntl()
  const [selectOptions, setSelectOptions] = useState(SELECT_OPTIONS_INIT)
  const [filtersLoading, setFiltersLoading] = useState(false)
  const [filtersState, setFiltersState] = useState(getFilterState(intl))
  const [dataTableColumns, setDataTableColumns] = useState([])
  const [init, setInit] = useState(false)
  const [persisteFiltros, setPersisteFiltros] = useState(getPersistedStoragedState)

  useEffect(() => {
    if (props.filterResetInputs) {
      setFiltersState(FILTER_STATE_INIT);
      window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: false, filtros: FILTER_STATE_INIT }));
    }
  }, [props.filterResetInputs])

  useEffect(() => {
    setFiltersLoading(true)
    //set selectOption values
    Promise.all([
      Utils.getData('/bases/select-filtered-by-user/base/'),
      Utils.getData('/modelo-tipos/select'),
      Utils.getData('/gerenciadores/select-filtered-by-user'),
      Utils.getData('/tickets/estados'),
      Utils.getData('/servicios/select'),
      Utils.getData('/regiones/select'),
      Utils.getData('/centros-costos/select'),
      Utils.getData('/cebes/simple-search'),
      Utils.getData('/paises/select'),
      Utils.getData('/tickets/origenes/select'),
    ]).then((data) => {
      let ticketEstados = localStorage.getItem('ticketsEstadoStrings') ? JSON.parse(localStorage.getItem('ticketsEstadoStrings')) : []
      let estados = [{ value: 'PENDIENTES', label: intl.formatMessage({ id: 'tickets.estados.PENDIENTES', defaultMessage: 'PENDIENTES' }) }]
      if (data[3]) data[3].map(e => (estados.push({ value: e, label: ticketEstados[e] })))

      let tipos = [
        { value: 'DEFAULT', label: intl.formatMessage({ id: 'ticketsGridFilters.select_por_defecto', defaultMessage: 'POR DEFECTO' }) },
        { value: 'CORRECTIVO', label: intl.formatMessage({ id: 'ticketsGridFilters.select_correctivo', defaultMessage: 'CORRECTIVO' }) },
        { value: 'PREVENTIVO', label: intl.formatMessage({ id: 'ticketsGridFilters.select_preventivo', defaultMessage: 'PREVENTIVO' }) },
        { value: 'VENCIMIENTO', label: intl.formatMessage({ id: 'ticketsGridFilters.select_vencimiento', defaultMessage: 'VENCIMIENTO' }) }
      ]
      if (ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true') {
        tipos.push({ value: 'GESTORIA', label: intl.formatMessage({ id: 'ticketsGridFilters.select_gestoria', defaultMessage: 'GESTORIA' }) })
      }

      let selectOptions = {
        bases: data[0] ? data[0] : [],
        tipoModelos: data[1] ? data[1] : [],
        gerenciadores: data[2] ? data[2] : [],
        estados: estados,
        servicios: data[4] ? data[4] : [],
        regiones: data[5] ? data[5] : [],
        cecos: data[6] ? data[6] : [],
        cebes: data[7] ? data[7] : [],
        paises: data[8] ? data[8] : [],
        origenes: data[9] ? data[9] : [],
        tipos: tipos,
        enTallerSelect: [
          { value: 'true', label: intl.formatMessage({ id: 'ticketsGridFilters.select_en_taller', defaultMessage: 'EN TALLER' }) },
          { value: 1, label: intl.formatMessage({ id: 'ticketsGridFilters.select_si_sin_verificacion', defaultMessage: 'SI - Sin Verificación' }) },
          { value: 2, label: intl.formatMessage({ id: 'ticketsGridFilters.select_si_verificado_GPS', defaultMessage: 'SI - Verificado GPS' }) },
          { value: 3, label: intl.formatMessage({ id: 'ticketsGridFilters.select_no_verificado_GPS', defaultMessage: 'No - Verificado GPS' }) },
          { value: 0, label: intl.formatMessage({ id: 'ticketsGridFilters.select_no', defaultMessage: 'NO' }) }
        ],
        abonoSelect: [
          { value: true, label: intl.formatMessage({ id: 'ticketsGridFilters.abono_select_si', defaultMessage: 'SI' }) },
          { value: false, label: intl.formatMessage({ id: 'ticketsGridFilters.abono_select_no', defaultMessage: 'NO' }) }
        ],
        realizadoSelect: [
          { value: true, label: intl.formatMessage({ id: 'ticketsGridFilters.realizado_select_si', defaultMessage: 'SI' }) },
          { value: false, label: intl.formatMessage({ id: 'ticketsGridFilters.realizado_select_no', defaultMessage: 'NO' }) }
        ],
        nivelesAprobacion: [{ value: 'N1', label: 'N1' }, { value: 'N2', label: 'N2' }, { value: 'N3', label: 'N3' }]
      }

      setSelectOptions(selectOptions)
      setFiltersLoading(false)
    })
  }, [])

  useEffect(() => {
    setFiltersLoading(true)
    if (init === false) {
      let query = JSON.parse(JSON.stringify(filtersState))
      setPersisteFiltros(true);
      query['estado'] = query['estado'] ? query['estado']['value'] : ''
      query['base'] = query['base'] ? query['base']['value'] : ''
      query['entidad'] = query['entidad'] ? query['entidad'] : ''
      query['ticketTipo'] = query['ticketTipo'] ? query['ticketTipo']['value'] : ''
      query['nivelAprobacionPresupuesto'] = query['nivelAprobacionPresupuesto'] ? query['nivelAprobacionPresupuesto']['value'] : ''
      query['servicio'] = query['servicio'] ? query['servicio']['value'] : ''
      query['gerenciador'] = query['gerenciador'] ? query['gerenciador']['value'] : ''
      query['region'] = query['region'] ? query['region']['value'] : ''
      query['pais'] = query['pais'] ? query['pais'].map(e => e.value).join(',') : ''
      query['centroCostos'] = query['centroCostos'] ? query['centroCostos']['value'] : ''
      query['centroBeneficios'] = query['centroBeneficios'] ? query['centroBeneficios']['value'] : ''
      query['enTaller'] = query['enTaller'] ? query['enTaller']['value'] : ''
      query['fechaRealizado'] = query['fechaRealizado'] ? query['fechaRealizado']['value'] : ''
      query['abono'] = query['abono'] ? query['abono']['value'] : ''
      query['tipoModelo'] = query['tipoModelo'] ? query['tipoModelo']['value'] : ''
      query['fechaHoraTurno'] = query['fechaHoraTurno'] ? query['fechaHoraTurno'] : ''
      query['fechaHoraAlta'] = query['fechaHoraAlta'] ? query['fechaHoraAlta'] : ''
      query['movilPersona'] = query['movilPersona'] ? query['movilPersona'] : ''
      query['origen'] = query['origen'] ? query['origen']['value'] : ''

      //filtros export
      setInit(true)
      window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: props.persisteFiltros, filtros: filtersState }));
      props.setFilterQuery(query);
      setFiltersLoading(false)

      if (props.persisteFiltros) {
        props.setSearchActive(true);
      }
      return;
    }

    if (persisteFiltros) {
      props.setDropdownActive(true)
    }
    else {
      setFiltersState(FILTER_STATE_INIT)
      props.setDropdownActive(false)
    }
    setFiltersLoading(false)

    window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: persisteFiltros, filtros: filtersState }))
  }, [persisteFiltros])

  useEffect(() => {
    if (props.limpiarFiltros) {
      props.filterResetInputs()
      setFiltersState(FILTER_STATE_INIT)
    }

    if (props.cardKpi) {
      let obj = null;
      let key = null;
      let filter = JSON.parse(JSON.stringify(FILTER_STATE_INIT));
      if (props.cardKpi === 'EN_TALLER') {
        obj = selectOptions.enTallerSelect.find(option => option.value === 'true')
        filter['enTaller'] = obj;
        key = 'enTaller';

      } else {
        obj = selectOptions.tipos.find(option => option.value === props.cardKpi)
        filter['ticketTipo'] = obj;
        key = 'ticketTipo';

      }
      let query = JSON.parse(JSON.stringify(filter));
      setFiltersState(filter);
      query['estado'] = query['estado'] ? query['estado']['value'] : ''
      query['base'] = query['base'] ? query['base']['value'] : ''
      query['entidad'] = query['entidad'] ? query['entidad']['value'] : ''
      query['movilPersona'] = query['movilPersona'] ? query['movilPersona']['value'] : ''
      query['ticketTipo'] = query['ticketTipo'] ? query['ticketTipo']['value'] : ''
      query['nivelAprobacionPresupuesto'] = query['nivelAprobacionPresupuesto'] ? query['nivelAprobacionPresupuesto']['value'] : ''
      query['servicio'] = query['servicio'] ? query['servicio']['value'] : ''
      query['gerenciador'] = query['gerenciador'] ? query['gerenciador']['value'] : ''
      query['region'] = query['region'] ? query['region']['value'] : ''
      query['pais'] = query['pais'] ? query['pais'].map(e => e.value).join(',') : ''
      query['centroCostos'] = query['centroCostos'] ? query['centroCostos']['value'] : ''
      query['centroBeneficios'] = query['centroBeneficios'] ? query['centroBeneficios']['value'] : ''
      query['enTaller'] = query['enTaller'] ? query['enTaller']['value'] : ''
      query['fechaRealizado'] = query['fechaRealizado'] ? query['fechaRealizado']['value'] : ''
      query['abono'] = query['abono'] ? query['abono']['value'] : ''
      query['tipoModelo'] = query['tipoModelo'] ? query['tipoModelo']['value'] : ''
      query['fechaHoraTurno'] = query['fechaHoraTurno'] ? query['fechaHoraTurno'] : ''
      query['fechaHoraAlta'] = query['fechaHoraAlta'] ? query['fechaHoraAlta'] : ''
      query['origen'] = query['origen'] ? query['origen'] : ''

      //props.setFilterQuery(query);
      setFiltersLoading(false)
      window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: persisteFiltros, filtros: filter }));
      return;
    }
  }, [props.limpiarFiltros, props.cardKpi])

  const updateToDataTables = (filters) => {
    setFiltersLoading(true)
    if (dataTableColumns.length) {
      for (let filter in filters) {
        let data = '';

        if (filters[filter] !== null && filters[filter] !== '') {
          if (Array.isArray(filters[filter])) {
            data = filters[filter].map(e => e.value).join(',') // para inputs multiples
          }
          else if (filters[filter].value) {
            data = filters[filter].value
          }
          else {
            data = filters[filter]
          }

          props.dtData.column(dataTableColumns.indexOf(filter)).search(data).draw()
        }

      }
    }
    setFiltersLoading(false)
  }

  const handleChange = (key, object) => {
    //avisar siempre al componente padre cuando el filtro cambie 
    props.onFilterChange();

    if (!init) {
      setFiltersLoading(true);
      let query = {}
      query = JSON.parse(JSON.stringify(filtersState));

      if (props.persisteFiltros) {
        props.setSearchActive(true);
      }
      if (filtersState.estado.length) {
        query['estado'] = filtersState.estado.map((estado) => estado.value).join(',')
      }
      query['ticketTipo'] = filtersState.ticketTipo ? filtersState.ticketTipo : ''
      query['id'] = filtersState.id ? filtersState.id : ''
      query['entidad'] = filtersState.entidad ? filtersState.entidad : ''
      query['estado'] = filtersState.estado ? filtersState.estado : ''
      query['nivelAprobacionPresupuesto'] = filtersState.nivelAprobacionPresupuesto ? filtersState.nivelAprobacionPresupuesto : ''
      query['fechaHoraAlta'] = filtersState.fechaHoraAlta ? filtersState.fechaHoraAlta : ''
      query['detalle'] = filtersState.detalle ? filtersState.detalle : ''
      query['titular'] = filtersState.titular ? filtersState.titular.value : ''
      query['servicio'] = filtersState.servicio ? filtersState.servicio : ''
      query['base'] = filtersState.base ? filtersState.base : ''

      query['gerenciador'] = filtersState.gerenciador ? filtersState.gerenciador : ''
      query['region'] = filtersState.region ? filtersState.region : ''
      query['pais'] = filtersState.pais ? filtersState.pais : ''
      query['fechaRealizado'] = filtersState.fechaRealizado ? filtersState.fechaRealizado : ''
      query['enTaller'] = filtersState.enTaller ? filtersState.enTaller : ''
      query['detalle'] = filtersState.detalle ? filtersState.detalle : ''
      query['abono'] = filtersState.abono ? filtersState.abono : ''
      query['centroCostos'] = filtersState.centroCostos ? filtersState.centroCostos : ''
      query['responsable1'] = filtersState.responsable1 ? filtersState.responsable1 : ''
      query['centroBeneficios'] = filtersState.centroBeneficios ? filtersState.centroBeneficios : ''
      query['fechaHoraTurno'] = filtersState.fechaHoraTurno ? filtersState.fechaHoraTurno : ''
      query['tipoModelo'] = filtersState.tipoModelo ? filtersState.tipoModelo : ''
      query['movilPersona'] = filtersState.movilPersona ? filtersState.movilPersona : ''
      query['origen'] = filtersState.origen ? filtersState.origen : ''

      //Controlo si viene filtro de kpi por dashboard
      if (props.cardKpi) {
        query['ticketTipo'] = props.cardKpi
      }
      setInit(true)

      window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: props.persisteFiltros, filtros: filtersState }));
      props.setFilterQuery(query);
      setFiltersLoading(false)
      return;
    }
    else {
      let currentFilters = JSON.parse(window.localStorage.getItem('TicketsGridFilterNew'))['filtros'];
      if (key === 'base') {
        if (!object) {
          searchBase('');
        }
      }
      if (key === 'centroBeneficios') {
        if (!object) {
          searchCebe('');
        }
      }
      if (JSON.stringify(currentFilters[key]) === JSON.stringify(object)) { // si el nuevo filtro aplicado es igual al filtro viejo no hace nada
        return;
      }

      setFiltersLoading(true)
      let query = {}
      query = { ...props.filterQuery };
      let data
      switch (key) {
        // Selects
        case 'ticketTipo':
        case 'estado':
        case 'nivelAprobacionPresupuesto':
        case 'servicio':
        case 'gerenciador':
        case 'region':
        case 'base':
        case 'centroCostos':
        case 'centroBeneficios':
        case 'enTaller':
        case 'fechaRealizado':
        case 'abono':
        case 'tipoModelo':
        case 'origen':
          data = object ? object.value : ''
          break
        case 'pais':
          data = object ? object.map(e => e.value).join(',') : '' // para input multiples
          break
      
        // Inputs
        default:
          data = object
          break
      }

      //filtros para export
      query[key] = data
      props.setFilterQuery(query);


      //filtrosPersistidos
      window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: persisteFiltros, filtros: filtersState }))
      setFiltersLoading(false)
      return;
    }
  }

  const handleOnEnter = (e, name, value) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleChange(name, value)
    }
  }

  const searchBase = (e) => {
    let value = '';
    if (e)
      value = e.target.value;
    Utils.getData("/bases/simple-search").then((res) => {
      let selectOptionsCopy = JSON.parse(JSON.stringify(selectOptions));
      selectOptionsCopy.bases = res.map((b) => ({ value: b.id, label: b.descripcion }));
      setSelectOptions(selectOptionsCopy);
    });
  };

  const searchCebe = (e) => {
    let value = '';
    if (e)
      value = e.target.value;
    Utils.getData("/cebes/simple-search").then((res) => {
      let selectOptionsCopy = JSON.parse(JSON.stringify(selectOptions));
      selectOptionsCopy.cebes = res;
      setSelectOptions(selectOptionsCopy);
    });
  };

  useEffect(() => {
    if (init) {
      if (props.persisteFiltros) {
        window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: true, filtros: filtersState }));
      }
      else {
        setFiltersState(FILTER_STATE_INIT);
        props.setFilterQuery(FILTER_QUERY_INIT);
        window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: false, filtros: FILTER_STATE_INIT }));
      }
    }
  }, [props.persisteFiltros])

  return (
    <>
      <Collapse in={props.dropdownActive}>
        <Grid container item xs={12} spacing={3} style={{ marginBottom: 20 }}>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="ticketTipo"
                disabled={filtersLoading}
                options={selectOptions.tipos}
                value={filtersState.ticketTipo}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, ticketTipo: value })}
                onKeyDown={(e) => handleOnEnter(e, 'ticketTipo', filtersState.ticketTipo)}
                onBlur={(e) => handleChange("ticketTipo", filtersState.ticketTipo)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.tipo_label", defaultMessage: "Tipo" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={1}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.id_label", defaultMessage: "Tkt" })}
                name="id"
                value={filtersState.id}
                onChange={(e) => setFiltersState({ ...filtersState, id: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'id', filtersState.id)}
                onBlur={(e) => handleChange("id", filtersState.id)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="estado"
                disabled={filtersLoading}
                options={selectOptions.estados}
                value={filtersState.estado}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, estado: value })}
                onKeyDown={(e) => handleOnEnter(e, 'estado', filtersState.estado)}
                onBlur={(e) => handleChange("estado", filtersState.estado)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.estado_label", defaultMessage: "Estado" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.creado_label", defaultMessage: "Creado" })}
                name="fechaHoraAlta"
                value={filtersState.fechaHoraAlta}
                onChange={(e) => setFiltersState({ ...filtersState, fechaHoraAlta: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'fechaHoraAlta', filtersState.fechaHoraAlta)}
                onBlur={(e) => handleChange("fechaHoraAlta", filtersState.fechaHoraAlta)}
                disabled={filtersLoading}
                className="col-12"
                type="date"
                format={"DD-MM-YYYY"}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.nivelAprobacionPresupuesto && { display: "none" }} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="nivelesDeAprobacion"
                disabled={filtersLoading}
                options={selectOptions.nivelesAprobacion}
                value={filtersState.nivelAprobacionPresupuesto}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, nivelAprobacionPresupuesto: value })}
                onKeyDown={(e) => handleOnEnter(e, 'nivelAprobacionPresupuesto', filtersState.nivelAprobacionPresupuesto)}
                onBlur={(e) => handleChange("nivelAprobacionPresupuesto", filtersState.nivelAprobacionPresupuesto)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.nivelesDeAprobacion_label", defaultMessage: "Nivel Aprobacion" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.detalle_label", defaultMessage: "Detalle" })}
                name="detalle"
                value={filtersState.detalle}
                onChange={(e) => setFiltersState({ ...filtersState, detalle: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'detalle', filtersState.detalle)}
                onBlur={(e) => handleChange("detalle", filtersState.detalle)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="servicio"
                disabled={filtersLoading}
                options={selectOptions.servicios}
                value={filtersState.servicio}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, servicio: value })}
                onKeyDown={(e) => handleOnEnter(e, 'servicio', filtersState.servicio)}
                onBlur={(e) => handleChange("servicio", filtersState.servicio)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.servicio_label", defaultMessage: "Servicio" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.entidad_label", defaultMessage: "Entidad" })}
                name="entidad"
                value={filtersState.entidad}
                onChange={(e) => setFiltersState({ ...filtersState, entidad: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'entidad', filtersState.entidad)}
                onBlur={(e) => handleChange("entidad", filtersState.entidad)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="proveedor"
                disabled={filtersLoading}
                options={selectOptions.gerenciadores}
                value={filtersState.gerenciador}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, gerenciador: value })}
                onKeyDown={(e) => handleOnEnter(e, 'gerenciador', filtersState.gerenciador)}
                onBlur={(e) => handleChange("gerenciador", filtersState.gerenciador)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.proveedor_label", defaultMessage: "Proveedor" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.region && { display: "none" }} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="region"
                disabled={filtersLoading}
                options={selectOptions.regiones}
                value={filtersState.region}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, region: value })}
                onKeyDown={(e) => handleOnEnter(e, 'region', filtersState.region)}
                onBlur={(e) => handleChange("region", filtersState.region)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.region_label", defaultMessage: "Region" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="base"
                disabled={filtersLoading}
                options={selectOptions.bases}
                value={filtersState.base}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, base: value })}
                onBlur={(e) => handleChange("base", filtersState.base)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.base_label", defaultMessage: "Base" })}
                    onChange={searchBase}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.pais && { display: "none" }} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="pais"
                multiple={true}
                disabled={filtersLoading}
                options={selectOptions.paises}
                value={filtersState.pais}
                defaultValue={[]}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, pais: value })}
                onKeyDown={(e) => handleOnEnter(e, 'pais', filtersState.pais)}
                onBlur={(e) => handleChange("pais", filtersState.pais)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.pais_label", defaultMessage: "Pais" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.centroCostos && { display: "none" }} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="centroCostos"
                disabled={filtersLoading}
                options={selectOptions.cecos}
                value={filtersState.centroCostos}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, centroCostos: value })}
                onKeyDown={(e) => handleOnEnter(e, 'centroCostos', filtersState.centroCostos)}
                onBlur={(e) => handleChange("centroCostos", filtersState.centroCostos)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.ceco_label", defaultMessage: "Centro Costos" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.centroBeneficios && { display: "none" }} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="centroBeneficios"
                disabled={filtersLoading}
                options={selectOptions.cebes}
                value={filtersState.centroBeneficios}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, centroBeneficios: value })}
                onKeyDown={(e) => handleOnEnter(e, 'centroBeneficios', filtersState.centroBeneficios)}
                onBlur={(e) => handleChange("centroBeneficios", filtersState.centroBeneficios)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.cebe_label", defaultMessage: "Centro Beneficios" })}
                    onChange={searchCebe}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.responsable1 && { display: "none" }} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.responsable_label", defaultMessage: "Responsable" })}
                name="responsable1"
                value={filtersState.responsable1}
                onChange={(e) => setFiltersState({ ...filtersState, responsable1: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'responsable1', filtersState.responsable1)}
                onBlur={(e) => handleChange("responsable1", filtersState.responsable1)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="taller"
                disabled={filtersLoading}
                options={selectOptions.enTallerSelect}
                value={filtersState.enTaller}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, enTaller: value })}
                onKeyDown={(e) => handleOnEnter(e, 'enTaller', filtersState.enTaller)}
                onBlur={(e) => handleChange("enTaller", filtersState.enTaller)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.taller_label", defaultMessage: "Taller" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.tt_label", defaultMessage: "TT" })}
                name="mttTotal"
                value={filtersState.mttTotal}
                onChange={(e) => setFiltersState({ ...filtersState, mttTotal: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'mttTotal', filtersState.mttTotal)}
                onBlur={(e) => handleChange("mttTotal", filtersState.mttTotal)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.tp_label", defaultMessage: "TP" })}
                name="mttParcial"
                value={filtersState.mttParcial}
                onChange={(e) => setFiltersState({ ...filtersState, mttParcial: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'mttParcial', filtersState.mttParcial)}
                onBlur={(e) => handleChange("mttParcial", filtersState.mttParcial)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.encuestaSatisfaccion && { display: "none" }} component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.encuestaSatisfaccion_label", defaultMessage: "Satisfaccion" })}
                name="encuestaNivelSatisfaccion"
                value={filtersState.encuestaNivelSatisfaccion}
                onChange={(e) => setFiltersState({ ...filtersState, encuestaNivelSatisfaccion: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'encuestaNivelSatisfaccion', filtersState.encuestaNivelSatisfaccion)}
                onBlur={(e) => handleChange("encuestaNivelSatisfaccion", filtersState.encuestaNivelSatisfaccion)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="fechaRealizado"
                disabled={filtersLoading}
                options={selectOptions.realizadoSelect}
                value={filtersState.fechaRealizado}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, fechaRealizado: value })}
                onKeyDown={(e) => handleOnEnter(e, 'fechaRealizado', filtersState.fechaRealizado)}
                onBlur={(e) => handleChange("fechaRealizado", filtersState.fechaRealizado)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.realizado_label", defaultMessage: "Realizado" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.fechaHoraTurno && { display: "none" }} component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.turno_label", defaultMessage: "Turno" })}
                name="fechaHoraTurno"
                value={filtersState.fechaHoraTurno}
                onChange={(e) => setFiltersState({ ...filtersState, fechaHoraTurno: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'fechaHoraTurno', filtersState.fechaHoraTurno)}
                onBlur={(e) => handleChange("fechaHoraTurno", filtersState.fechaHoraTurno)}
                disabled={filtersLoading}
                className="col-12"
                type="date"
                // isClearable={true}
                format={"DD-MM-YYYY"}
                // disableFuture
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.abono && { display: "none" }} component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="abono"
                disabled={filtersLoading}
                options={selectOptions.abonoSelect}
                value={filtersState.abono}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, abono: value })}
                onKeyDown={(e) => handleOnEnter(e, 'abono', filtersState.abono)}
                onBlur={(e) => handleChange("abono", filtersState.abono)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.abono_label", defaultMessage: "Abono" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.tipoModelo && { display: "none" }} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="tipoModelo"
                disabled={filtersLoading}
                options={selectOptions.tipoModelos}
                value={filtersState.tipoModelo}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, tipoModelo: value })}
                onKeyDown={(e) => handleOnEnter(e, 'tipoModelo', filtersState.tipoModelo)}
                onBlur={(e) => handleChange("tipoModelo", filtersState.tipoModelo)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.tipoModelo_label", defaultMessage: "Tipo Modelo" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid style={!props.columnsEnabled.origen_checklist ? { display: "none" } : {}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="origen"
                disabled={filtersLoading}
                options={selectOptions.origenes}
                value={filtersState.origen} // Make sure filtersState.origen is defined and set initially
                getOptionLabel={(option) => (option ? option.label : "")}
                onChange={(e, value) => setFiltersState({ ...filtersState, origen: value })}
                onKeyDown={(e) => handleOnEnter(e, 'origen', filtersState.origen)}
                onBlur={(e) => handleChange("origen", filtersState.origen)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={intl.formatMessage({
                      id: "ticketsGridFilters.render.origen",
                      defaultMessage: "Origen ticket",
                    })}
                  />
                )}
              />
            </form>
          </Grid>


          <Grid component={Box} item xs={2} className="align-self-end">
            <form noValidate autoComplete="off">
              <div className="d-flex inline justify-content-around align-items-center">
                <FormattedMessage
                  id="ticketsGridFilters.render.filtros.persistir_filtros"
                  defaultMessage="Persistir Filtros"
                />
                <Switch
                  onChange={(e) => setPersisteFiltros(e.target.checked)}
                  checked={persisteFiltros}
                  disabled={filtersLoading}
                  offColor="#FF4961"
                  onColor="#28D094"
                />
              </div>
            </form>
          </Grid>

        </Grid>
      </Collapse>
    </>
  )
}


export default TicketGridFilters
