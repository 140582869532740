import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import 'bootstrap/dist/js/bootstrap.js';
import Config from '../commons/config/Config.js';
import Layout from './layout/Layout.js'
import Login from './auth/Login.js'
import Activate from './auth/Activate.js'
import ForgotPassword from './auth/ForgotPassword.js'
import RecoverPassword from './auth/RecoverPassword.js'
import AzureCallback from './auth/AzureCallback.js'
import InternalLogin from './auth/InternalLogin.js'
import {IntlProvider} from 'react-intl'
import PointerLogin from './auth/PointerLogin.js'
import GeotabLogin from './auth/GeotabLogin.js'

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			authorized: null,
			configBussines: null
		}
	}

	componentDidMount() {

		this.getConfig()
		.then(() => {
			this.checkSession()
			.then(() => {
				//this.getConfigBusiness()
			});
		});
		this.getConfigBusiness();
	}

	getConfig() {
		// Carga configuración desde el archivo config.json.
		return fetch('/config/config.json', {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
		.then(response => response.json())
		.then(configData => {
			localStorage.setItem('config', JSON.stringify(configData));
		})
		.catch(function(error) {
			// TODO: Manejo de errores
			console.log(error);
		});
	}

	getConfigBusiness() {
		// Carga configuración de negocio.
		return fetch(Config.get('apiUrlBase') + '/commons/config-business', {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
		.then(response => response.json())
		.then(data => {

			localStorage.setItem("configBusiness", JSON.stringify(data));
			this.setState({configBussines: true});
		})
		.catch(function(error) {
			// TODO: Manejo de errores
			console.log(error);
		});
	}

	checkSession() {
		// Verifica si existe una sesión activa para el token.
		return new Promise((resolve, reject) => {
			if(localStorage.getItem('token')) {
				fetch(Config.get('apiUrlBase') + '/auth/check', {
					method: 'GET',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization-Token': localStorage.getItem('token')
					}
				}).then(response => {
					this.setState({
						authorized: response.status === 200
					});
					if(response.status === 200) resolve();
				}).catch(function(error) {
					// TODO: Manejo de errores
					console.log(error);
				});
			} else {
				this.setState({
					authorized: false
				});
			}
		});
	}

    render() {
        return (
            <IntlProvider locale={this.props.locale} messages={this.props.messages} defaultLocale="es">
                <React.Fragment>
                    {(this.state.authorized === null || this.state.configBussines === null) ?
                            null : (!this.state.authorized ?
                                <Router>
                                    <Switch>
                                        <Route exact path='/' component={Login} />
                                        <Route path='/activacion/:activationKey' component={Activate} />
                                        <Route path='/olvide-clave' component={ForgotPassword} />
                                        <Route path='/recuperar-clave/:activationKey' component={RecoverPassword} />
                                        <Route path='/auth/azure/callback' component={AzureCallback} />
                                        <Route path='/auth/pointer' component={PointerLogin} />
										<Route path='/auth/geotab' component={GeotabLogin} />
                                        <Route path='/auth/internal/login' component={InternalLogin} />
                                        <Route component={Login} />
                                    </Switch>
                                </Router>

                                : <Router>
									<Switch>
										<Route path='/auth/pointer' component={PointerLogin} />
										<Route path='/auth/geotab' component={GeotabLogin} />
										<Layout authorized={this.state.authorized} />
									</Switch>
								</Router>
                    )}
                </React.Fragment>
            </IntlProvider>
        );

  	}
}

export default App;
